#alphabet-container {
    display: grid;
    grid-template-rows: repeat(auto-fit, 50px);
    grid-template-columns: repeat(auto-fit, 88px);
    grid-gap: 15px;
    padding: 20px;
    margin: 30px auto;
    max-width: 1100px;
    justify-content: center;
}

.alphabet {
    width: 50px;
    height: 50px;
    border: 3px solid #333;
    text-align: center;
    box-shadow: 4px 4px 0 0 #063c56;
    background: #2e538375;
    position: relative;
    overflow: hidden;
    transform-origin: bottom right;
    color: #ffffff;
}

.single-alphabet {
    font-size: 35px;
    font-weight: bold;
    padding: 15px 0;
}

.alphabet:hover {
    transform: translate(-0em, -0.1em);
    -webkit-transform: translate(-0em, -0.1em);
}

.alphabet-span-background {
    content: "";
    height: 100%;
    width: 100%;
    background: #ffd100;
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.75, 0, 0.25, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.75, 0, 0.25, 1);
    transition: transform 0.2s cubic-bezier(0.75, 0, 0.25, 1);
    transition: transform 0.2s cubic-bezier(0.75, 0, 0.25, 1),
        -webkit-transform 0.2s cubic-bezier(0.75, 0, 0.25, 1);
}

.shifter {
    animation: shift 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    display: block;
    padding: 0;
}

@keyframes shift {
    0% {
        transform: translateX(0);
    }
    44.9% {
        transform: translateX(20px);
    }
    45% {
        transform: translateX(-20px);
    }
    55% {
        transform: translateX(-20px);
    }
    0% {
        transform: translateX(0);
    }
}
