.footer {
    max-width: 900px;
    margin: 20px auto;
    text-align: center;
}

.footer-link a {
    color: #ffffff86;
    background-image: linear-gradient(to bottom, transparent 65%, #ada50f46 0);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    text-decoration: none;
    transition: background-size 0.4s ease;
    font-weight: bold;
}

.footer-link a:hover {
    background-size: 100% 100%;
    cursor: pointer;
}
