* {
    box-sizing: border-box;
}

body {
    height: 100vh;
    background: radial-gradient(ellipse at bottom, #2e5383 0%, #090a0f 100%);
    overflow: hidden;
    filter: drop-shadow(0 0 10px white);
    font-family: "Chivo Mono", "Franklin Gothic Medium", "Arial Narrow", Arial,
        "Times New Roman", Times, sans-serif, serif;
    color: #ffffffd8;
}
