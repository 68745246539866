.word-alphabet {
    text-align: center;
    margin: 2px;
}

.placeholder-label {
    text-align: center;
    font-weight: bold;
    color: #ffffffd8;
    font-size: 20px;
}

.placeholder-span {
    display: block;
    text-align: center;
    border-bottom: 5px solid #ffffff7f;
    width: 20px;
}
