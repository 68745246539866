.category {
    margin: 0 5px;
    border: 3px solid #333;
    box-shadow: 4px 4px 0 0 #063c56;
    padding: 10px;
    background: #2e538375;
    color: aliceblue;
    height: 50px;
    width: 150px;
    cursor: pointer;
}

.category:hover {
    background: #5981b575;
}

.single-category {
    cursor: pointer;
}

.selected-category {
    background: #476e81;
    color: #ffffff;
}

.completed-category {
    background: #d6b42a !important;
    color: #ffffff;
    pointer-events: none;
}
