#navbar {
    text-align: center;
    max-width: 900px;
    margin: 15px auto;
    font-weight: bolder;
    color: #325481;
    font-size: 90px;
    font-family: Rubik Spray Paint;
    letter-spacing: 10px;
}
